.edit-report-page {
  .edit-report-guideline-box {
    padding: 15px 20px;
    border: 1px solid $guideline-box-border;
    margin: 0 0 20px;
  }

  button {
    // display: flex;

    // svg {
    //   margin: 0 0 0 7px;
    // }
  }

  .form-group{
    flex-wrap: nowrap;
    align-items: center;
    &>label{
      margin: 0 10px 0 0;
      padding: 0;
      flex: 1 0 80px;
    }
    &>.form-control{
      margin: 0;
      flex: 1 1 100%;
      width: 100%;
    }
  }
  .edit-report-gl-container{
    padding: 16px 12px;
    margin-bottom: 30px;
    border: 1px  solid #969faa;
    border-radius: 5px;
  }

  .edit-report-gl-row{
    display:flex;
    justify-content: space-between;
  }
  .edit-report-gl-guideline,
  .edit-report-gl-violation{
    flex: 0 0 49%;
    width: 49%;
  }

  .edit-report-guideline-details{
    margin: 30px 0;
    input{
      min-width: auto;
      width: 100%;
      margin:0;
    }
    button{
      min-width: 40px;
      width: 40px;
      height: 37px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    th,
    td{
      width: 32%;
      padding: 0 4px 10px;
    }
    th:last-child,
    td:last-child{
      width: 4%
    }
  }

  .edit-report-gl-error{
    padding: 10px 0;
    color: red
  }

}


//
// Dropdowns
// --------------------------------------------------

.dropdown {
  position: relative;

  .dropdown-control {
    cursor: pointer;
  }

  .dropdown-pane {
    display: none;
    position: absolute;
    top: 48px;
    right: -5px;
    z-index: 1200; // Must be greater than navigation or full screen loader falls behind navigation
    background-color: $dropdown-bg;
    padding: 11px 11px 16px;
    border: 1px solid $dropdown-border;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 7px 0 $dropdown-shadow;

    &::before {
      content: "";
      position: absolute;
      right: 4px;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 14px 10px;
      border-color: $dropdown-arrow-bg;
      z-index: 52;
    }

    &::after {
      content: "";
      position: absolute;
      right: 3px;
      top: -11px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 11px;
      border-color: $dropdown-arrow-border;
      z-index: 51;
    }
  }

  &.open .dropdown-pane {
    display: block;
  }
}

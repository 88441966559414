//
// Sign In Modal
// --------------------------------------------------

.signin-container {
  display: flex;
  flex-direction: column;
  min-width: 315px;
  max-width: 315px;
  margin: 150px auto 0;

  img {
    height: 120px;
    width: 120px;
    margin: 0 auto 25px;
  }

  h1 {
    font-weight: normal;
    font-size: 32px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .password-reset {
    text-align: right;
    padding: 0 8px 0 0;
    margin: -10px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .password-reset-link {
      font-size: 14px;
      color: $login-reset-link;
    }

    &.password-reset-back {
      margin: 10px 0 15px;
    }
  }
}

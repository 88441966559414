//
// Website overview
// --------------------------------------------------
.overview-website {
  .overview-website-graphs {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;

      .chart {
        &:first-child {
          width: 33%;
        }

        + .chart {
          width: 67%;
        }
      }
    }
  }

  .chart-severity-summary {
    @include media-breakpoint-down(sm) {
      height: 300px;
    }

    display: flex;
    flex-direction: column;

    .chart-container {
      flex: 1 1 0%;
    }
  }

  .charts-principles-container {
    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;

    figure {
      margin-bottom: 0;
    }

    .chart {
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        width: 25%;
      }

      margin-bottom: 1rem;
      width: 50%;
    }

    figcaption {
      display: flex;
      flex-direction: column;
      color: $overview-principles-caption;
      font-size: 14px;
    }

    .figure-caption {
      color: $overview-principles-caption;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.table {
  border-collapse: collapse;

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid $report-table-border;
  }
}
@media print {

  .overview-website {
    .overview-website-graphs {
        display: flex;
        flex-direction: row;
  
        .chart {
          &:first-child {
            width: 33%;
          }
  
          + .chart {
            width: 67%;
          }
        }
      }
      .charts-principles-container {
       
          flex-wrap: nowrap;
      }
      .chart {
          margin-bottom: 0;
          width: 25%;
          
      }
    }
}
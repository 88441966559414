//
// Figures
// --------------------------------------------------

figure {
  margin: 0 0 1rem;

  figcaption {
    text-align: center;
    color: $caption;
  }
}

//
// Accessibility related a11y
// --------------------------------------------------

.a11y-sr-only,
.a11y-kb-only {
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

}

.a11y-kb-only {
  &:active,
  &:focus {
    position: static;
    height: auto;
    width: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

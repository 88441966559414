//
// HTML & Body
// --------------------------------------------------

html,
body {
  padding: 0;
  border: 0;
  margin: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
}

body {
  display: flex;
  justify-content: center;
  min-height: 100%;
  color: $body;
  background-color: $body-bg;
  font-family: $body-font-family;
  overflow-x: hidden;
}

// stylelint-disable selector-max-id
#root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  align-content: stretch;
  justify-content: flex-start;
  min-height: 100%;

  main {
    flex: 1 1 auto;
    align-content: stretch;
    justify-content: flex-start;
    padding: 15px 20px;

    > section {
      max-width: 960px;
      margin: 0 auto 30px;
    }
  }
}
// stylelint-enable selector-max-id

//
// Sign Up Block
// --------------------------------------------------

.sign-up-block {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 15px 0;
  border: 1px solid $sign-up-block-border;
  border-radius: 5px;
  margin: 15px 0 0;

  a,
  a:link,
  a:visited {
    padding: 0 0 0 7px;
    margin: 0;
  }
}

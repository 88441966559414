//
// Code blocks
// --------------------------------------------------

pre {
  background: $code-bg;

  code {
    color: $code;
  }
}

code {
  background: $code-bg;
  color: $code;
}
td code{
  white-space: break-spaces;
  width: 100%;
  display: inline-block;
}

.report-content {
  font-family: $report-header-font-family;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $text;
  text-align: left;

  .container {
    margin-top: .5rem;
    max-width: 960px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    .header {
      display: flex;
      flex-direction: column;

      img {
        height: 80px;
        vertical-align: middle;
        border: 0;
      }

      .subtitle {
        margin: 0 0 1rem;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      text-align: center;
      line-height: 1.2;
      font-family: $report-header-font-family;
      font-size: 2.75rem;
      font-weight: 400;
      margin: 0 0 1rem;
    }

    h2,
    h3,
    h4 {
      font-size: 2rem;
      font-weight: 500;
    }

    h3 {
      font-size: 1.75rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    p {
      margin: 1rem 0;
    }

    table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;

      td {
        word-wrap: break-word;
      }

      caption {
        padding-top: .75rem;
        padding-bottom: .75rem;
        color: $report-table-caption;
        text-align: center;
        caption-side: bottom;
      }
    }
  }
}

@media (min-width: 576px) {
  .report-content .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .report-content .container {
    max-width: 720px;

    h1,
    h2,
    h3,
    h4 {
      text-align: left;
    }
  }
}

@media (min-width: 992px) {
  .report-content .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .report-content {
    display: grid;
    grid-template-columns: 15em 1fr;
    margin: 0 auto;
    width: 90%;
    max-width: 100em;
  }
}
@media print {
    #root{
    zoom: 80%
  }
    .report-content .container {
    h1{
      text-align: center;
    }
    h2,
    h3,
    h4 {
      text-align: left;
    }
  }
  .info-site>p{
    text-align: left;
  }
  
  #root main > section{
    overflow-x: hidden;
  }


  .overview-website .overview-website-graphs .chart:first-child
  {
    width: 33%;
    max-width: 33%;
    flex: 0 0 33%
  }
  .overview-website .overview-website-graphs .chart + .chart{
    width: 67%;
    max-width: 67%;
    flex: 0 0 67%
  }

}
.table-responsive{
    .severity{
      p{
        img{
          max-width: 100%;
        }
      }
    }
}
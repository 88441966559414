//
// Modals
// --------------------------------------------------

.modal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $modal-backdrop-bg;
  overflow: hidden;
  outline: 0;
  z-index: 1050;

  .modal {
    position: relative;
    display: block;
    min-height: 100px;
    max-width: 780px;
    z-index: 1051;
    text-align: left;
    background-color: $modal-bg;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $modal-shadow-1, 0 3px 4px 0 $modal-shadow-2, 0 1px 5px 0 $modal-shadow-3;
    margin: 75px auto 0;

    .modal-header {
      padding: 18px 30px;
      border-bottom: 1px solid $modal-inner-border;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin: 0;
      }
    }

    .modal-close {
      position: absolute;
      top: 15px;
      right: 15px;
      display: block;

      button {
        width: 18px;
        min-width: 18px;
        font-size: 28px;
        line-height: 28px;
        background: $modal-close-bg;
        color: $body;
        padding: 0;
        border: 0;
        margin: 0;

        &:hover {
          $background-color: $modal-close-bg-hover;
        }
      }
    }

    .modal-body {
      max-width: 720px;
      padding: 18px 30px;
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      padding: 14px 30px;
      border-top: 1px solid $modal-inner-border;

      button,
      .button {
        margin: 0 0 0 8px;
      }
    }

    &.modal-wide {
      max-width: 980px;

      .modal-body {
        max-width: 920px;
      }
    }
  }

  &.open {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.request-urls-audit-modal{
  .form-group{
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    flex: 1 1 auto;
    padding-right: 16px;
  } 
  .form-group label {
      flex: 0 0 100px;
      width: 100px;
      padding:  0;
  }
  input{
    flex: 1 1 auto;
    width: auto;
    margin:0;
  }
}
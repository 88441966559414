//
// HTML Headers
// --------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $body-font-family;
  font-weight: bold;
  margin: 0 0 30px;
}

h1 {
  font-size: 42px;
  line-height: 48px;
}

h2 {
  font-size: 36px;
  line-height: 42px;
}

h3 {
  font-size: 24px;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
}

h5 {
  font-size: 18px;
  line-height: 20px;
}

h6 {
  font-size: 16px;
  line-height: 18px;
}

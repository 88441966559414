//
// Page Footer
// --------------------------------------------------

footer {
  text-align: center;
  padding: 13px 22px;
  border: 0;
  border-top: 1px solid $footer-border;

  .footer-nav-list {
    display: flex;
    flex-direction: row;
    list-style: outside none;
    margin: 0 auto;

    > li {
      display: flex;
      flex: 0 0 25%;
      align-items: center;
      justify-content: center;

      a,
      a:link,
      a:visited,
      .button-link {
        color: $footer-link;
        font-size: 16px;
        padding: 5px 10px;

        &:hover,
        &:active,
        &:focus {
          color: $footer-link-hover;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  footer .footer-nav-list {
    max-width: 960px;
  }
}

@media print {
  footer {
    display: none;
  }
}

.base-box-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;

  .base-box {
    flex: 1 1 auto;
    background-color: $base-box-bg;
    padding: 16px;
    border-radius: 0;

    .base-box-inner-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    .collapse-box-header {
      position: relative;
      color: $collapse-box-header;
      background-color: $collapse-box-header-bg;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      padding: 11px 16px;
      border: 0;
      border-bottom: 1px solid $collapse-box-border;
      border-radius: 0;

      .collapse-box-icon {
        position: absolute;
        right: 16px;
        top: 11px;
        min-width: auto;
        font-size: 16px;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        padding: 1px 5px;
      }

      &.collapsed {
        border-bottom: 0;
      }
    }

    .collapse-box-body {
      padding: 16px;

      .base-box {
        box-shadow: none;
      }

      &.collapsed {
        display: none;
      }
    }

    &.collapse-box {
      padding: 0;
      border: 1px solid $collapse-box-border;
    }
  }
}

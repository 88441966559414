@import "@toast-ui/editor/dist/toastui-editor";

// stylelint-disable selector-class-pattern
.toastui-editor-defaultUI button {
  min-width: auto;

  &:hover {
    background-color: transparent;
  }
}
// stylelint-enable selector-class-pattern

//
// Buttons
// --------------------------------------------------

// stylelint-disable selector-no-qualifying-type
button {
  min-width: 160px;
  color: $button;
  font-size: 18px;
  background-color: $button-bg;
  padding: 10px 12px;
  border: 1px solid $button-border;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: $button-bg-hover;
  }

  &:disabled,
  &[disabled] {
    opacity: .65;
    cursor: not-allowed;
  }

  &.primary {
    color: $button-primary;
    background: $button-primary-bg;
    border: 0;

    &:hover {
      background: $button-primary-bg-hover;
    }
  }

  &.button-link {
    background: transparent;
    color: $link;
    border: 0;

    &:hover {
      color: $link-hover;
      text-decoration: underline;
    }
  }

  &.danger {
    background-color: $danger;
    color: $button-primary;
  }
}

.MuiIconButton-root{
  min-width: auto;
}
.MuiTablePagination-displayedRows{
  margin: 0
}
// stylelint-enable selector-no-qualifying-type

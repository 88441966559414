//
// Detailed summary
// --------------------------------------------------

.table-detailed-summary {
  th,
  td {
    border: 1px solid $report-table-border;
    padding: .75rem;
  }

  thead tr:first-child th {
    text-align: center;
  }

  tbody th {
    @include media-breakpoint-up(md) {
      width: 50px;
    }

    writing-mode: sideways-lr;
    vertical-align: middle;
  }

  // Give severity links sufficient contrast
  .severity-cell a {
    color: $detailed-summary-link;
  }
}

.detailed-page {
  @include media-breakpoint-up(lg) {
    .th-guideline {
      width: 15%;
    }

    .th-test {
      width: 20%;
    }

    .th-examples {
      width: 45%;
    }

    .th-violations {
      width: 10%;
    }

    .th-severity {
      width: 10%;
    }
  }

  h3 {
    border-bottom: 1px solid $detailed-page-header;
    padding: .5rem 0;
  }
}

.table-detailed-page {
  // For larger devices, the fixed column widths can allow the cells to align between pages
  @include media-breakpoint-up(lg) {
    table-layout: fixed;
  }

  thead th {
    background: $detailed-table-header-bg;
  }

  tbody {
    th,
    td {
      background: $detailed-table-body-bg;
    }
  }

  th,
  td {
    border: 1px solid $table-border;
    padding: .25rem .5rem;
    font-size: 14px;
  }

  .severity-label {
    font-weight: bold;
  }

  p,
  img,
  pre {
    margin: .5rem 0;
  }

  img {
    display: block;
    border: 1px solid $detailed-table-img-border;
  }

  p:first-child,
  img:first-child,
  pre:first-child {
    margin-top: 0;
  }

  p:last-child,
  img:last-child,
  pre:last-child {
    margin-bottom: 0;
  }
}

//
// Tables
// This is for the React table component. It is not for the tables in the report.
// --------------------------------------------------

.table-admin {
  width: 100%;
  max-width: 100%;
  border: 1px solid $table-border;
  border-collapse: collapse;
  margin: 0;

  tr {
    padding: 0 20px 0 0;

    th,
    td {
      text-align: left;
      white-space: nowrap;
      padding: 10px;
      border: 0;
    }

    th {
      font-weight: 600;
      background-color: $table-header-bg;
      border-bottom: 1px solid $table-header-border;

      &:hover .hoverable-anchor {
        display: inline-block;
      }
    }

    td {
      font-weight: normal;
      border-top: 1px solid $table-cell-border;
    }
  }

  &.striped tbody tr:nth-child(even) {
    background-color: $table-row-striped;

    &:hover {
      background-color: $table-row-hover;
    }
  }

  tbody tr &:hover {
    background-color: $table-row-hover;
  }

  &.sort-table thead tr th {
    padding: 0;

    .sort-toggle {
      position: relative;
      display: block;
      padding: 10px 40px 10px 10px;
      cursor: pointer;

      svg {
        position: absolute;
        right: 4px;
        top: 12px;
      }
    }
  }

  caption {
    text-align: center;
  }
}

.filter-table .filter-table-controls {
  display: flex;

  .form-group {
    position: relative;
    flex: 1 1 auto;

    .form-control {
      padding-right: 30px;
    }

    .clear-button {
      position: absolute;
      left: 286px;
      z-index: 3;
      top: 5px;
      min-width: 20px;
      font-size: 16px;
      padding: 3px 7px;
      border-radius: 50%;
    }
  }

  button {
    min-width: 80px;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 15px;
  }
}

//
// Tags
// --------------------------------------------------

.tag {
  min-width: 64px;
  min-height: 37px;
  background-color: $tag-bg;
  padding: 8px 12px;
  border: 2px solid $tag-border;
  border-radius: 100px;
}

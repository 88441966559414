//
// Links
// --------------------------------------------------

a,
a:link,
a:visited {
  color: $link;
  text-decoration: none;
}

// stylelint-disable selector-no-qualifying-type
a:hover,
a:active,
a.active {
  color: $link-hover;
  text-decoration: underline;
}
// stylelint-enable selector-no-qualifying-type

.hoverable-anchor {
  display: none;
  margin-left: .5em;
  font-size: .75em;
}

.downloadLink:link,
.downloadLink:visited 
{
  color: #1c2023
}
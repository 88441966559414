//
// Loading Indicator
// --------------------------------------------------

.loading-indicator {
  text-align: center;
  margin: 15px 0;

  svg {
    animation: load-spin 2s linear infinite;
  }
}

@keyframes load-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

//
// Pages overview
// --------------------------------------------------
.overview-pages {
  h2,
  h3 {
    font-family: $report-header-font-family;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 1rem;
  }

  table {
    margin-bottom: 1rem;
    color: $overview-table;
  }

  .table-overview-pages-severity {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: calc(80%);

    th,
    td {
      border: 1px solid $report-table-border;
      padding: .75rem;
    }
  }
}

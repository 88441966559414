//
// Base colors
// --------------------------------------------------
$white: #fff;
$light-gray: #dee2e6;
$gray: #767676;
$dark-gray: #333;
$black: #000;
$block-shadow: rgba(0, 0, 0, .1);

//
// ADA Report Specific colors
// --------------------------------------------------
$primary: #4285f4;
$text: #1c2023;
$light-border: #dee2e6;
$danger: darken(#d55257, 5%);

//
// Severity colors
// --------------------------------------------------
$severity-0: #76923c;
$severity-1: #508bce;
$severity-2: #f8f045;
$severity-3: #f89645;
$severity-4: #d66b4a;
$severity-5: #d55257;

//
// Borders
// --------------------------------------------------
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

// Navigation
// --------------------------------------------------
$nav-bg: #fff;
$nav-border: $light-gray;
$side-navigation-border: #efefef;
$side-navigation-border-hover: #333;
$side-navigation-link: $text;

//
// Body
// --------------------------------------------------
$body: $text;
$body-bg: $white;
$body-font-family: roboto, sans-serif;

//
// Login
// --------------------------------------------------
$login-bg: $white;
$create-account-border: $gray;
$login-reset-link: $text;

//
// Page Header
// --------------------------------------------------
$header-border: $light-gray;
$header-link: $text;
$header-link-hover: darken($header-link, 5%);
$header-shadow: $block-shadow;
$user-badge-bg: #eee;
$user-badge-border: #8492a6;

//
// Alerts
// --------------------------------------------------
$alert: $text;
$alert-bg: $white;
$alert-close-hover: $light-gray;
$alert-default-bg: $alert-bg;
$alert-icon: $text;

//
// Boxes
// --------------------------------------------------
$base-box-bg: $white;
$collapse-box-header: #47525e;
$collapse-box-header-bg: #fafafa;
$collapse-box-border: #969faa;

//
// Buttons
// --------------------------------------------------
$button: $text;
$button-bg: $white;
$button-bg-hover: darken($button-bg, 5%);
$button-border: #5a6978;
$button-primary: $white;
$button-primary-bg: $primary;
$button-primary-bg-hover: darken($primary, 5%);
$button-disabled: $white;

//
// Code blocks
// --------------------------------------------------
$code-bg: #f6f6f6;
$code: #333;

//
// Detailed summary
// --------------------------------------------------
$detailed-summary-link: #000050;
$detailed-page-header: #ddd;
$detailed-table-header-bg: #f2f2f2;
$detailed-table-body-bg: #fff;
$detailed-table-img-border: #000;

//
// Dropdowns
// --------------------------------------------------
$dropdown: #212529;
$dropdown-bg: $white;
$dropdown-border: rgba(0, 0, 0, .15);
$dropdown-shadow: rgba(0, 0, 0, .3);
$dropdown-arrow-bg: transparent transparent $dropdown-bg;
$dropdown-arrow-border: transparent transparent $dropdown-border;

//
// Edit Report
// --------------------------------------------------
$guideline-box-border: #969faa;

//
// Figures
// --------------------------------------------------
$caption: #6c757d;

//
// Input
// --------------------------------------------------
$input-bg: $white;
$input-border: $gray;

//
// Links
// --------------------------------------------------
$link: #0056b3;
$link-hover: darken($link, 5%);

//
// Modal
// --------------------------------------------------
$modal-backdrop-bg: rgba(51, 51, 51, .5);
$modal-bg: $white;
$modal-close-bg: $white;
$modal-close-bg-hover: #3c3c3c;
$modal-inner-border: $light-gray;
$modal-shadow-1: rgba(0, 0, 0, .1);
$modal-shadow-2: rgba(0, 0, 0, .12);
$modal-shadow-3: rgba(0, 0, 0, .14);

//
// Report Block
// --------------------------------------------------
$report-block-border: $light-border;
$report-block-shadow: $block-shadow;
$report-block-shadow-hover: darken($report-block-shadow, 5%);

$report-table-caption: #6c757d;
$report-header-font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//
// Settings Block
// --------------------------------------------------
$settings-block-border: $light-border;
$settings-block-shadow: $block-shadow;

//
// Sign Up Block
// --------------------------------------------------
$sign-up-block-border: $input-border;

//
// Summary info
// --------------------------------------------------
$info-guidelines-bg: #f2f2f2;

// Tables
$table-border: #c0ccda;
$table-header-bg: #eff2f7;
$table-header-border: $table-border;
$table-cell-border: $table-border;
$table-row-striped: #f9fafc;
$table-row-hover: darken($table-row-striped, 2%);
$overview-table: #111519;
$report-table-border: $black;

//
// Tags
// --------------------------------------------------
$tag-bg: #f5f5f5;
$tag-border: #5a6978;

//
// Footer
// --------------------------------------------------
$footer-border: $nav-border;
$footer-link: #6c757d;
$footer-link-hover: $footer-link;

//
// Website overview
// --------------------------------------------------
$overview-principles-caption: #000;

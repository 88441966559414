//
// Summary info
// --------------------------------------------------

.info-main {
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.info-site {
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
    text-align: center;
  }

  p {
    margin: 0;
    line-height: normal;
  }

  .subtitle {
    font-size: 22px;
    font-weight: 600;
  }
}

.info-guidelines-summary {
  @include media-breakpoint-up(md) {
    margin-right: 0;
    margin-left: auto;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background: $info-guidelines-bg;
  padding: 4px 12px;
  text-align: center;
  font-size: 18px;
  position: relative;
}

.info-guidelines-summary-doc-note{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(0, 105%);
  font-size: 14px;
}

.info-guidelines-passed {
  font-size: 24px;
  font-weight: bold;
}

@media print {
  .info-main {
    
      flex-direction: row;
      padding-bottom: 1.5rem;
  }
  .info-guidelines-summary {
      margin-right: 0;
      margin-left: auto;
  }
}

//
// Person Table
// --------------------------------------------------

.person-table button,
.organization-table button {
  min-width: 50px;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 12px;
  margin: 0 8px 0 0;
}

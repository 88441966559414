//
// Severity
// --------------------------------------------------

@include severity(0, $severity-0);
@include severity(1, $severity-1);
@include severity(2, $severity-2);
@include severity(3, $severity-3);
@include severity(4, $severity-4);
@include severity(5, $severity-5);

.severity-label .progress {
  border-radius: 0;
}


//
// Severity tables
// --------------------------------------------------

.table-severity-legend {
  @include media-breakpoint-up(md) {
    margin-right: 0;
  }
  @include media-breakpoint-down(sm) {
    // The descriptors are unappealing at lower widths
    tr {
      th:first-child,
      td:first-child {
        display: none;
      }
    }

    th,
    td {
      text-align: center;
    }
  }

  margin: 0 auto;
  width: auto;

  th,
  td {
    border: 0;
    padding: .25rem;
    vertical-align: middle;
  }

  tbody th {
    font-weight: normal;
  }

  td:first-child {
    padding-right: .5rem;
    text-align: right;
  }

  .severity-cell {
    width: 50px;
    text-align: center;
  }
}

.table-with-severity {
  .page-heading {
    @include media-breakpoint-up(md) {
      writing-mode: sideways-lr;
    }

    width: 50px;
  }

  .severity-cell {
    text-align: center;
  }
}


.table-severity-threshold {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(50%);

  th,
  td {
    border: 1px solid $report-table-border;
    text-align: center;
    padding: .75rem;
  }

  thead th {
    vertical-align: middle;
  }

  tbody th {
    writing-mode: sideways-lr;
    width: 50px;
    vertical-align: middle;
  }

  .severity-cell {
    text-align: center;
  }
}


@media print {
  // table{
  //   display: none !important;
  // }
  .table-severity-legend {
      margin-right: 0;
      th:first-child,
      td:first-child{
        display: table-cell !important;
      }
      .severity-cell{
        width: 50px !important;
      }
  }
  .table-with-severity {
    .page-heading {
        writing-mode: sideways-lr;
      }
  }
  .table-detailed-page {
    table-layout: auto;
}
}
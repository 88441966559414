//
// Report Block
// This is used on the report listing page.
// --------------------------------------------------

.report-block-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .report-block {
    flex: 0 0 420px;
    width: 420px;
    min-height: 300px;
    padding: 10px 0;
    border: 1px solid $report-block-border;
    border-radius: 4px;
    margin: 0 50px 30px 0;
    box-shadow: 0 2px 7px 0 $report-block-shadow;
    cursor: pointer;

    h2 {
      font-size: 22px;
      line-height: 28px;
      margin: 0 0 3px 15px;
    }

    .report-block-header {
      display: flex;
      flex-direction: row;
      margin: 0 15px 15px;

      .report-website {
        flex: 1 1 auto;
        word-break: break-all;
        padding: 0 10px 0 0;
      }

      .report-guideline {
        flex: 0 0 auto;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
      }
    }

    img {
      margin: 0 0 25px;
    }

    .report-block-footer {
      display: flex;
      flex-direction: row;
      margin: 0 15px;

      .report-pages {
        flex: 1 1 auto;

        .report-page-count {
          font-size: 20px;
          line-height: 26px;
          font-weight: bold;
        }
      }

      .report-guidelines {
        flex: 1 1 auto;
        text-align: center;

        .report-guideline-count {
          font-size: 20px;
          line-height: 26px;
          font-weight: bold;
        }
      }
    }

    .report-block-action {
      text-align: center;
      padding: 15px 0 0;

      button:nth-of-type(2) {
        margin-left: 10px;
      }
    }

    &.request-report-block {
      text-align: center;

      .icon {
        padding: 100px 0 0;
      }
    }

    &:hover {
      box-shadow: 0 5px 10px 0 $report-block-shadow-hover;
    }
  }
}

.report-block-filter {
  display: block;
}

.report-block-filter-1 {
  display: block;
  margin-top: 10px;
  margin-right: 5px;
}

.report-block-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto 20px;
  justify-content: flex-start;
}

.archived-report-block-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto 20px;
  max-width: 960px;
  padding-right: 70px;
}

.report-legalnote{
  display: none;
}

@media print {
  .report-legalnote{
    display: block;
  }
  .pages-overview-container,
  .detailed-summary-container,
  .appendix-a-container{
    page-break-before: always;
  }
}

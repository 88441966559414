//
// Forms
// --------------------------------------------------

input,
select {
  min-width: 314px;
  padding: 10px 12px;
  border: 1px solid $input-border;
  border-radius: 5px;
  margin: 0 0 15px;
}

select {
  min-width: 315px;
}

.form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 15px;

  label {
    flex: 0 1 150px;
    padding: 8px 0 0;
  }

  input,
  select {
    margin: 0 0 5px;
  }

  &.has-icon {
    position: relative;

    input {
      padding-left: 27px;
    }

    svg,
    .icon {
      position: absolute;
      top: 11px;
      left: 8px;
    }
  }

  .form-group-error {
    flex: 0 0 100%;
    min-width: 100%;
    font-size: .9em;
    color: $danger;
    margin: 0 0 0 150px;
  }

  &.no-label .form-group-error {
    margin: 0;
  }
}

// Make the fields a little wider on company info form in light of long country names.
.company-info-form,
.add-edit-organization-form {
  input {
    min-width: 349px;
  }

  select {
    min-width: 350px;
  }
}

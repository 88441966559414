// Vendor variables
@import "bootstrap-variables";
@import "bootstrap-mixins";

// Variables and functions
@import "variables";
@import "mixins";

// Global
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Main components and Page parts
@import "a11y";
@import "alert";
@import "body";
@import "breadcrumb";
@import "button";
@import "code";
@import "collapse-box";
@import "detailed-summary";
@import "dropdown";
@import "edit-report";
@import "figures";
@import "font";
@import "form";
@import "header";
@import "link";
@import "list";
@import "loading-indicator";
@import "modal";
@import "page-footer";
@import "page-header";
@import "pages-overview";
@import "paragraph";
@import "person-table";
@import "report-block";
@import "report-content";
@import "settings-block";
@import "severity";
@import "side-navigation";
@import "signin";
@import "sign-up-block";
@import "summary-info";
@import "toast-editor";
@import "table";
@import "tag";
@import "website-overview";

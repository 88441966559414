//
// Page Header
// --------------------------------------------------
// This is called page header to differentiate it from HTML headers.

header {
  display: flex;
  flex-direction: row;
  padding: 13px 22px;
  border: 0;
  border-bottom: 1px solid $header-border;
  box-shadow: 0 2px 7px 0 $header-shadow;
  position: -webkit-sticky;
  position: sticky;
  // position: fixed;
  // width: 100%;
  top: 0;
  padding: 10px;
  z-index: 9999 !important;
  background-color: white;

  .brand {
    padding: 0;

    img {
      display: flex;
      flex: 0 0 157px;
      margin: 0 37px;
      height: 45px;
    }
  }

  nav {
    flex: 1 1 auto;

    .header-nav-list {
      display: flex;
      flex-direction: row;
      list-style: outside none;
      padding: 13px 0 0;
      margin: 0;

      > li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px 0 0;
      }
    }

    a,
    a:link,
    a:visited,
    .button-link {
      color: $header-link;
      font-size: 16px;
      padding: 5px 10px;

      &:hover,
      &:active,
      &:focus {
        color: $header-link-hover;
      }
    }
  }

  .company-dropdown,
  .report-dropdown,
  .user-dropdown {
    .dropdown-control {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        padding: 0 0 0 5px;
      }
    }

    .dropdown-pane {
      top: 30px;
      right: -14px;
      min-width: 200px;

      a,
      a:link,
      a:visited,
      .button-link {
        color: $header-link;
        font-size: 16px;

        &:hover,
        &:active,
        &:focus {
          color: $header-link-hover;
        }
      }

      ul {
        list-style: outside none;
        padding: 0;
        margin: 0;

        li {
          padding: 5px 0;

          a {
            display: block;
            padding: 5px 0;
          }
        }
      }

      button {
        text-align: left;
        padding: 5px 0;
      }
    }

    .user-badge {
      width: 45px;
      height: 45px;
      padding: 13px 10px;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      border-radius: 50%;
      background-color: $user-badge-bg;
      border: 1px solid $user-badge-border;
    }
  }

  .user-dropdown .dropdown-pane {
    top: 52px;
    right: 3px;
  }
}

@media print {
  header {
    display: none;
  }
}

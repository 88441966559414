//
// Settings Block
// --------------------------------------------------

.settings-block {
  padding: 15px 20px;
  border: 1px solid $settings-block-border;
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 $settings-block-shadow;

  h2 {
    font-size: 22px;
    line-height: 28px;
  }
}

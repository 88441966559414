@use "sass:math";

//
// Sass Mixins
// --------------------------------------------------

@mixin severity($number, $color) {
  .table-with-severity .severity-cell.severity-#{$number} {
    background: $color;

    // Unfortunately, we have to use the !important
    // rule here to override Bootstrap CSS that forces
    // printed table cell backgrounds to be white.
    // stylelint-disable declaration-no-important
    @media print {
      background: $color !important;
    }
    // stylelint-enable declaration-no-important
  }

  .severity-#{$number} {
    &.severity-label .progress-bar {
      background: $color;
      width: percentage(math.div($number, 5));
    }

    .guideline-label {
      border-bottom: 5px solid $color;
    }
  }
}

//
// Alerts
// --------------------------------------------------

.alert {
  position: relative;
  padding: 13px 30px 13px 65px;
  border-radius: 0;
  margin: 0 0 20px;
  background: $alert-bg;

  svg,
  .alert-icon {
    position: absolute;
    top: 2px;
    left: 0;
    z-index: 9;
    width: 50px;
    height: 100%;
    color: $alert-icon;
    font-size: 18px;
    line-height: 50px;
    text-align: center;
    background: $alert-default-bg;

    &::after {
      position: absolute;
      top: 14px;
      left: 47px;
      content: "";
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
    }
  }

  .alert-close {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 22px;
    min-width: 22px;
    color: $alert;
    font-size: 24px;
    line-height: 24px;
    background: $alert-bg;
    padding: 0 3px;
    border: 0;
    cursor: pointer;

    &:hover {
      background: $alert-close-hover;
    }
  }

  &.close {
    display: none;
  }
}

//
// Side navigation
// --------------------------------------------------

.report-content {
  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: 15em 1fr;
    margin: 0 auto;
    width: 90%;
    max-width: 100em;
  }
}

.section-nav {
  @include media-breakpoint-down(xl) {
    display: none;
  }
  @include media-breakpoint-up(xl) {
    display: block;
  }

  position: sticky;
  top: 7rem;
  align-self: start;
  margin-top: 1rem;
  padding-left: 0;
  max-height: calc(100vh - 9rem);
  overflow-y: auto;

  li {
    margin-left: 1rem;
  }

  .active > a {
    border-color: $side-navigation-border-hover;
    font-weight: bold;
  }

  .anchorTags {
    display: block;
    transition: all 50ms ease-in-out;
    border-right: 1px solid $side-navigation-border;
    padding: .125rem 0;
    text-decoration: none;
    color: $side-navigation-link;
    margin: 0px;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: $side-navigation-border-hover;
    }
  }

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .downloadLink {
    border: none !important;
  }

  .download-div-1 {
    display: inline-block !important;
    margin-right: 12px !important;
  }

  .download-div-2 {
    display: inline-block !important;
  }

  .download-container {
    white-space: nowrap !important;
    overflow-x: auto !important;
  }
}
